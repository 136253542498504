<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Regenerative Landwirtschaft</h1>
      <p>
        Kurz gesagt, bei der regenerativen Landwirtschaft geht es darum, mit der Natur zu arbeiten, indem natürliche
        Effekte und Prozesse verbessert und dennoch ein Nutzen daraus gezogen wird.
      </p>
      <p>
        Unten ist eine Video-Playlist eines australischen Bodenwissenschaftlers, der ausführlich erklärt, was das
        bedeutet.
      </p>
      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video" width="560" height="315"
          src="https://www.youtube.com/embed/NjTAtMRejFA" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import CookieControlledContent from '../../components/CookieControlledContent.vue';

export default {
  name: "RegenerativeAgricultureDE",
  components: {
    Sidebar,
    CookieControlledContent
  },
}
</script>
